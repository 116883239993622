export function confirmationModal() {

  $(function() {

    let options = {};

    function handleOpenModal(e) {
      e.preventDefault();

      let $currentTarget = $(e.currentTarget);

      let formAction = $currentTarget.attr('href');
      let modalType = $currentTarget.data('modalType')
      let title = $currentTarget.data('title')
      let content = $currentTarget.data('content')
      let btnText = $currentTarget.data('btn-text')

      $modal.find('form').attr('action', formAction)
      $modal.find('.modal-title').html(title)
      $modal.find('.modal-body p').html(content)
      $modal.find('.modal-btn').html(btnText)

      if (modalType == 'delete') {
        $modal.find('.modal-btn').addClass('btn-danger')
      }

      $modal.modal(options);
    }

    function init() {
      $('body').on('click', '.delete-modal', handleOpenModal)
    }

    let $modal = $('#modal-default')

    if ($modal.length) {
      init();
    }

  });
}
