export function closeNotifications() {
  $(function() {
    function handleNotificationClose(e) {
      e.preventDefault();

      let $currentTarget = $(e.currentTarget);
      let $callout =  $currentTarget.parents('.callout');

      $callout.addClass('fade');
      setTimeout(function() {
        $callout.remove();
      }, 400);
    }

    $('.notifications-container').on('click', 'button', handleNotificationClose)
  });
}
