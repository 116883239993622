export function summernote() {

  $(function() {
    $('.summernote-input').summernote({
      toolbar: [
        // [groupName, [list of button]]
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['font', ['strikethrough', 'superscript', 'subscript']],
        ['fontsize', ['fontsize']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['height', ['height']],
        ['insert', ['link']],
        ['codeview']
      ]
    });

    $('.summernote-input').each(function(index, value) {
      var $value = $(value);

      if ($value.hasClass('is-invalid')) {
        $value.siblings('.note-editor').css({ 'border': '1px solid red' })
      }
    })
  })
}
