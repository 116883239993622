import { confirmationModal } from './confirmationModal.js';
import { summernote } from './summernote.js';
import { closeNotifications } from './closeNotifications.js';

confirmationModal();
summernote();
closeNotifications();

bsCustomFileInput.init();

$(function () {
  if (!window.matchMedia || (window.matchMedia("(max-width: 991px)").matches)) {
    $('[data-toggle="tooltip"]').tooltip()
  }
})
